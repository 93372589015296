.loading-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    pointer-events: none;
    width: 256px;
    height: 256px;
    background: url('/assets/loading.gif') no-repeat center;
    background-size: cover;
    transform: translate(-50%, -50%);
  }
}