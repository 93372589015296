.under-login-languages {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;

  > li {
    list-style-type: none;
    margin: 0px 0.5rem;
    padding: 0px;

    .language-flag {
      @extend .clear-button;
      opacity: 0.4;
      display: block;
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);

      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }

      &.selected {
        opacity: 1,
      }

      img {
        display: block;
      }
    }
  }
}