.table-image {
  height: 100px;
  background: #d9d9d9;
}

.cell-content {
  display: flex;
  align-items: center;
  height: 3rem;

  &.center {
    justify-content: center;
  }

  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }
}