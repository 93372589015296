.add-match-container {
  .add-match-field-wrapper {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.table-matches {
  width: 100%;

  tbody,
  thead {
    tr {
      th,td {
        &.match-remove {
          padding-left: 0px;
        }

        &.match-actions {
          padding-right: 0px;
        }

        &.match-home-club {
          padding-right: 2rem;
        }
      }
    }
  }

  thead {
    tr {
      th {
        padding: 0.5rem 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        font-size: 0.75em;

        .button-table-header {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  tbody {
    tr {

      &:hover {
        td {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      td {
        padding: 0.5rem;

        .attitude-wrapper {
          position: relative;
        }

        .attitude-estimation-level {
          padding: 0.35rem 0.3rem;
          display: block;
          text-align: center;
          background: rgba(0, 0, 0, 0.05);
          font-size: 0.75em;
          position: absolute;
          left: 0px;
          top: 0px;
          transform: translateX(-100%);
          height: 100%;

          box-sizing: border-box;

          &:before {
            content: '(';
          }

          &:after {
            content: ')';
          }
        }

        .attitude,
        .importance {
          padding: 0.35rem 0.4rem;
          display: block;
          font-weight: 600;
          text-align: center;
          width: 5rem;
          border: 0px;
          outline: none;
        }

        .importance {
          margin: 0px auto;
        }

        .retrieved-club-name {
          text-align: center;
          font-weight: 400;
          display: block;
          font-size: 0.75em;
          color: $textSecondaryColor;

          &:before {
            content: '(';
          }

          &:after {
            content: ')';
          }
        }

        .club-name {
          font-weight: bold;
          display: block;
          text-align: center;
          font-size: 1.125em;

          &.unimportant {
            font-weight: 400;
          }
        }

        .club-name-reserve {
          display: block;
          font-size: 0.75em;
          text-align: center;

          &:before {
            content: '(';
          }

          &:after {
            content: ')';
          }
        }

        .tier {
          padding: 0.35rem 0.4rem;
          display: block;
          text-align: center;

          &.tier-1 {
            background-color: rgba(136, 0, 255, 0.85);
          }

          &.tier-2 {
            background-color: rgba(136, 0, 255, 0.7);
          }

          &.tier-3 {
            background-color: rgba(136, 0, 255, 0.55);
          }

          &.tier-4 {
            background-color: rgba(136, 0, 255, 0.4);
          }

          &.tier-5 {
            background-color: rgba(136, 0, 255, 0.25);
          }

          &.tier-6 {
            background-color: rgba(136, 0, 255, 0.1);
          }
        }
      }
    }
  }
}